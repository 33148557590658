.main{
  height: 100vh;
  min-width: 1247px;
  min-height: 404px;
}

.header{
  background: #06101d;
  height: 48px;
  line-height: 48px;
  padding: 0 20px;
  box-shadow: 0px 0px 4px #121440;
  z-index: 5;
}

.sider{
  background: #06101d !important;
}

.logo {
  margin-right: 60px;
  height: 100%;
  float: left;
  line-height: 48px;
  font-size: 26px;
  font-weight: bolder;
  cursor: text;
  color: #46daff;
}

.footer{
  padding: 6px 0;
  text-align: center;
}

.content{
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
}

.topmenu{
  float: left;
}

.breadcrumb {
  margin: 0px 0px 18px 0px;
  padding: 8px 16px;
  border: 1px solid #e8e8e8;
  background-color: #FFFFFF;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.leftsider{
  overflow-y: auto;
  overflow-x: hidden;
  box-shadow: 0 0 4px #bbb;
  background: #06101d !important;
}
.leftsidermenu{
  height: 100%;
  min-height: 600px;
  border-right: 0 !important;
}

.font_size_lg{
  font-size: 14px !important;
}

.user_header_menu {
  float: right;
  background: #06101d !important;
}