.ant-timeline.ant-timeline-label .ant-timeline-item-label{
    width: 160px;
}

.ant-timeline.ant-timeline-alternate .ant-timeline-item-tail, .ant-timeline.ant-timeline-right .ant-timeline-item-tail, .ant-timeline.ant-timeline-label .ant-timeline-item-tail, .ant-timeline.ant-timeline-alternate .ant-timeline-item-head, .ant-timeline.ant-timeline-right .ant-timeline-item-head, .ant-timeline.ant-timeline-label .ant-timeline-item-head, .ant-timeline.ant-timeline-alternate .ant-timeline-item-head-custom, .ant-timeline.ant-timeline-right .ant-timeline-item-head-custom, .ant-timeline.ant-timeline-label .ant-timeline-item-head-custom{
    left: 186px;
}

.ant-timeline.ant-timeline-alternate .ant-timeline-item-left .ant-timeline-item-content, .ant-timeline.ant-timeline-right .ant-timeline-item-left .ant-timeline-item-content, .ant-timeline.ant-timeline-label .ant-timeline-item-left .ant-timeline-item-content{
    left: 180px;
    width: calc(50% + 200px);
}



.delivery-item.brief-model .delivery-brief {
    position: relative;
    padding: 0;
    top: 0
}

.delivery .delivery-item .delivery-brief .brief-content {
    padding: 6px 26px
}

.delivery-brief{
    padding: 0 20px;
    margin-top: -20px;
    margin-bottom: 30px;
}
.delivery-brief .brief-content {
    padding: 16px 30px;
    width: 100%;
    background-color: rgba(255,255,255,.8);
    box-shadow: 0 4px 16px 0 rgba(0,0,0,.1);
    border-radius: 4px;
}

.status-date-time{
    float: right;
}

.delivery-brief .date{
    font-weight: 700;
}

.delivery .delivery-item .delivery-brief .brief-content .wrapper_left {
    position: relative
}

.delivery .delivery-item .delivery-brief .brief-content .wrapper_left .bill-num {
    width: 500px;
    padding-bottom: 3px;
    margin-top: 10px
}

.delivery .delivery-item .delivery-brief .brief-content .wrapper_left .bill-num .apple-hotline {
    margin-left: 25px
}

.delivery .delivery-item .delivery-brief .brief-content .status-details {
    text-align: right
}

.locations{
    font-size: 16px;
    font-weight: 700;
}

.gray {
    color: #999;
}
.red{
    color: #dc1e32;
}

.ant-menu{
    background: #001529;
    color: white;
}

.ant-layout-header{
    background: #001529;
}

.menu{
    border-bottom-width: 0px;
    height: 64px;
}

.menu .ant-menu-item-selected{
    top: -1px;
    background: rgb(82, 166, 255);
    border-bottom-width: 0px !important;
    color: white !important;
    margin: 0 auto !important;
    padding: 0 20px !important;
    text-align: center;
}

.form .ant-form-item-label{
    text-align: left;
    padding-left: 10px;
}